// Fade
.fade-enter,
.fade-exit-active,
.fade-exit-done {
    opacity: 0;
}

.fade-enter-active,
.fade-enter-done {
    opacity: 1;
}

.fade-enter-active,
.fade-enter-done,
.fade-exit-active,
.fade-exit-done {
    transition: opacity 300ms;
}

// Slide bottom
.slide-bottom-enter,
.slide-bottom-exit-active,
.slide-bottom-exit-done {
    transform: translateY(100%);
}

.slide-bottom-enter-active,
.slide-bottom-enter-done {
    transform: translateY(0);
}

.slide-bottom-enter-active,
.slide-bottom-enter-done,
.slide-bottom-exit-active,
.slide-bottom-exit-done {
    transition: transform 300ms ease-out;
}

// Slide right
.slide-right-exit {
    right: 0;
}

.slide-right-enter,
.slide-right-exit-active,
.slide-right-exit-done {
    right: -100%;
}

.slide-right-enter-active,
.slide-right-enter-done {
    right: 0;
}

.slide-right-enter-active,
.slide-right-enter-done,
.slide-right-exit-active,
.slide-right-exit-done {
    transition: all 300ms ease-in-out;
}
